import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as jwtDecode from 'jwt-decode';
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface User {
  name: string;
  email: string;
  password: string;
  userId:string;
  refresh_token: string;
}


export interface Role {
  roleAccess : {
    myrole : Array<string>
  }
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  role : Role;
  isAuthenticated: boolean;
}

@Module
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  role = {} as Role;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  get currentRole() : Role {
    return this.role
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }
  

  @Mutation
  [Mutations.SET_AUTH](user) {
    // console.log(user)
    this.isAuthenticated = true;
    this.user = user;
    this.errors = [];
    JwtService.saveToken(user.refresh_token);
  }

  @Mutation
  [Mutations.SET_USER_ROLE](role){
    // console.log(role)
    this.role = role
    // localStorage.setItem('my_access',JSON.stringify(role))
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    // localStorage.removeItem('refresh_token')
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const params = {
      params: {
        ...credentials,
      },
    };
    
    return ApiService.post("login", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.GET_COMPANY](value){
    return ApiService.post('get_company',value)
    .then(data => {
      console.log(data)
    });
  }

  @Action
  [Actions.SET_ROLE](){
    return ApiService.query('get_myrole',{}).then(data => {
      this.context.commit(Mutations.SET_USER_ROLE, data.data);
    });
  } 

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    const params = {
      params: {
        ...payload,
      },
    };
    return ApiService.query("forgot_password", params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
        let decodedToken = {
            "user_id": 0,
            "name": "",
            "email": "",
            "companyId": 0,
            "iat": 0,
            "exp": 0
        }
      decodedToken = jwtDecode.jwtDecode(`${JwtService.getToken()}`);
      const currentTime = Math.floor(Date.now() / 1000);
      if(decodedToken.exp < currentTime){
        this.context.commit(Mutations.PURGE_AUTH);
        window.location.href = '#/sign-in'
      }
      if(window.location.hash != '#/sign-in'){
        ApiService.post('token',{}).then(data => {
          this.user.refresh_token = data.data.refresh_token
        }).catch(error => {
          this.context.commit(Mutations.PURGE_AUTH);
          setTimeout(() => {
            window.location.href = '#/sign-in'
          }, 200);
        })
      }
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }


  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}