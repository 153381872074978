import VueApexCharts from "vue3-apexcharts";
import { App } from "vue";

// /**
//  * Initialize VueApexChart component
//  * @param app vue instance
//  */
export function initApexCharts(app: App<Element>) {
  // app.use(VueApexCharts);
  app.component('apexchart', VueApexCharts);
}
