import ApiService from "@/core/services/ApiService";


const state = {
    branch : []
}


const mutations = {
    setBranch(state,branchs){
        state.branch = branchs
    }
}

const actions = {
    getListBranch({commit},params){
       return ApiService.query('master/branch/list',{params}).then(response => {
            commit('setBranch',response.data.data.rows)
            return response.data
        }).catch(error=> {
            return error.response.data
        })
    }
}


export default {
    namespace : false,
    state,
    mutations,
    actions
}