import ApiService from "@/core/services/ApiService";


const state = {
    customer: []
}


const mutations = {
    setCustomerAdd(state, customers) {
        state.customer = customers
    }
}

const actions = {
    getListOrderGroup({ commit }, params) {
        return ApiService.query('get_order_group', { params }).then(response => {
            commit('setCustomerAdd', response.data.customer)
            return response.data
        }).catch(error => {
            return error.response.data
        })
    }
}


export default {
    state,
    mutations,
    actions
}