import ApiService from "@/core/services/ApiService";


const state = {
    purchaseRequisitionGroup: []
}


const mutations = {
    setPurchaseRequisitionGrooup(state, requisitionData) {
        state.purchaseRequisitionGroup = requisitionData
    }
}

const actions = {
    getListRequisitionGroup({ commit }, params) {
        return ApiService.query('purchase_requisition/get_group', { params }).then(response => {
            commit('setPurchaseRequisitionGrooup', response.data.data.rows)
            return response.data
        }).catch(error => {
            return error.response.data
        })
    }
}


export default {
    namespace : true,
    state,
    mutations,
    actions
}