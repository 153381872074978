import ApiService from "@/core/services/ApiService";


const state = {
    itemAdjustment: []
}


const mutations = {
    setPurchaseRequisitionGrooup(state, adjustmentData) {
        state.itemAdjustment = adjustmentData
    }
}

const actions = {
    getListAjustment({ commit }, params) {
        return ApiService.query('item_adjustment/get_group', { params }).then(response => {
            commit('setPurchaseRequisitionGrooup', response.data.data.rows)
            return response.data
        }).catch(error => {
            return error.response.data
        })
    }
}


export default {
    namespace : true,
    state,
    mutations,
    actions
}